<template>
  <div>
    <div class="card primary">
      <div class="card-body p-5">
        <h3 class="card-title text-center">
          Passwort zurücksetzen
        </h3>
        <p
          class="text-center"
        >
          Geben Sie Ihre Email und Benutzernamen ein
        </p>
        <ca-alert
          v-if="passwordResetError"
          variant="danger"
          rounded
          :message="passwordResetError"
        />

        <template>
          <form
            novalidate
            @submit.prevent="resetPassword()"
          >
            <basic-input
              v-model="email"
              name="E-Mail"
              type="email"
              required
              placeholder="Email"
            />
            <basic-input
              v-model="traderId"
              name="Benutzer"
              required
              placeholder="Benutzer"
            />
            <button
              :disabled="passwordResetPending"
              type="submit"
              class="btn btn-primary text-white w-100 mt-3"
            >
              <span v-if="!passwordResetPending">
                Passwort zurücksetzen
              </span>
              <i
                v-if="passwordResetPending"
                class="fal fa-circle-notch fa-spin"
              />
            </button>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import feathers from '@/api'
import BasicInput from '@/components/BaseComponents/BasicInput'

export default {
  name: 'PasswordForgot',
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    BasicInput
  },
  data () {
    return {
      email: '',
      traderId: '',
      passwordResetPending: false,
      passwordResetError: null
    }
  },
  methods: {
    async resetPassword () {
      try {
        const valid = await this.$validator.validateAll()
        if (!valid || this.passwordResetPending) {
          return
        }
        this.passwordResetPending = true
        await feathers.service('auth-management').create({
          email: this.email,
          traderId: this.traderId
        },
        {
          query: {
            $client: {
              action: 'sendPasswordForgot'
            }
          }
        })
        await this.$router.push({ name: 'Login', query: { successType: 'passwordForgot' } })
      } catch (error) {
        this.passwordResetError = error
        console.error(error)
      } finally {
        this.passwordResetPending = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
